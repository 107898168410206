import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ExportFormat, ExportWidgetData } from '../../../models';
import {
  Subject,
  filter,
  finalize,
  interval,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { ExportsService } from '../../../services/exports.service';
import { Store } from '@ngrx/store';
import { RootState } from '../../../root-store/root-state';
import { ExportsActions } from '../../../root-store/exports-store';
import { NotificationService } from '../../../ui/services/notification.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import {
  NgFirstOrDefaultPipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'by-table-export-widget',
  templateUrl: './table-export-widget.component.html',
  styleUrls: ['./table-export-widget.component.scss'],
  imports: [
    CommonModule,
    NzButtonModule,
    NzToolTipModule,
    TranslateModule,
    NzDropDownModule,
    NgUpperFirstPipeModule,
    NgFirstOrDefaultPipeModule,
  ],
  standalone: true,
})
export class TableExportWidgetComponent implements OnChanges {
  @Input() columnsToExport: string[] = [];

  @Input() tooltipTitle = 'export';

  @Input() icon = 'fa-cloud-download';

  @Input() showTooltip = true;

  @Input() exportId = null;

  @Input() showAsPrintButton = false;

  @Input() formats: ExportFormat[] = ['csv', 'pdf', 'xlsx'];

  @Input() isMobileApp: boolean;

  @Output() export = new EventEmitter<ExportWidgetData>();

  isFileDownloaded = false;

  exportStartFromHere = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private store: Store<RootState>,
    private exportsService: ExportsService,
    private notifications: NotificationService,
  ) {}

  onExportPdf() {
    this.onExport('pdf');
  }

  onExportCsv() {
    this.onExport('csv');
  }

  onExportXlsx() {
    this.onExport('xlsx');
  }

  private onExport(format: ExportFormat) {
    this.exportStartFromHere = true;

    this.export.emit({
      format,
      type: 'export',
      columns: this.columnsToExport,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { exportId, showAsPrintButton } = changes;

    if (exportId?.currentValue && this.exportStartFromHere) {
      this.tooltipTitle = 'Caricamento esportazione';
      this.tryDownload();
    }

    if (showAsPrintButton?.currentValue) {
      this.icon = 'fa-print';
      this.tooltipTitle = 'print';
    }
  }

  get isLoading() {
    return this.exportId && this.exportStartFromHere;
  }

  tryDownload() {
    const download$ = new Subject();
    interval(3000)
      .pipe(
        take(5),
        takeUntil(download$),
        finalize(() => {
          this.exportId = null;

          if (!this.isFileDownloaded) {
            this.notifications.warning('click_go_to_exports', null, () =>
              this.router.navigate(['/exports']),
            );
          }

          this.isFileDownloaded = false;

          this.tooltipTitle = 'export';

          this.exportStartFromHere = false;

          this.cdr.detectChanges();
        }),
        switchMap(() => {
          return this.exportsService.checkExportCreation(this.exportId);
        }),
        filter(({ data }) => data.status === 'ready'),
        map(({ data }) => {
          this.isFileDownloaded = true;

          this.store.dispatch(ExportsActions.downloadFileRequest(data));

          download$.next(null);
        }),
      )
      .subscribe();
  }
}
