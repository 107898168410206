<nz-input-group
  id="searchGroupSelect"
  [nzPrefix]="prefixIcon"
  [nzSuffix]="suffixIcon"
>
  <nz-select
    [nzDropdownMatchSelectWidth]="false"
    bySpeechRecognition
    id="searchSelect"
    [class.search_select--mobile]="isMobileApp"
    [class.search_select--desktop]="!isMobileApp"
    #globalSearch
    [nzShowArrow]="false"
    (result)="onSpeechResult($event)"
    [enabled]="isMicrophoneActive"
    [nzServerSearch]="true"
    [nzShowSearch]="true"
    (nzOnSearch)="onSearch($event)"
    (nzOpenChange)="onOpenChange($event)"
    [(ngModel)]="selectedItem"
    (ngModelChange)="onChange($event)"
    [nzPlaceHolder]="
      showSelectPlaceholder ? ('search' | translate | upperfirst) : null
    "
    [class.by-fs-18]="isMobileApp"
    [nzNotFoundContent]="searchSelectNotFound"
  >
    <nz-option-group
      *ngIf="(results$ | async)?.guests?.length"
      [nzLabel]="'guests' | translate | capitalize"
    >
      <nz-option
        nzCustomContent
        *ngFor="let option of (results$ | async)?.guests"
        [nzValue]="option"
      >
        <ng-container
          *ngTemplateOutlet="
            guestsOptionTemplate;
            context: { data: { option: option } }
          "
        ></ng-container>
      </nz-option>
    </nz-option-group>
    <nz-option-group
      *ngIf="(results$ | async)?.rooms?.length"
      [nzLabel]="'rooms' | translate | capitalize"
    >
      <nz-option
        nzCustomContent
        *ngFor="let option of (results$ | async)?.rooms"
        [nzValue]="option"
      >
        <ng-container
          *ngTemplateOutlet="
            roomsOptionTemplate;
            context: { data: { option: option } }
          "
        ></ng-container>
      </nz-option>
    </nz-option-group>
    <nz-option-group
      *ngIf="searchControl.value?.length > 0"
      [nzLabel]="'search_instead_in' | translate | capitalize"
    >
      <nz-option
        *ngFor="let route of routes"
        [nzValue]="route.label"
        nzCustomContent
      >
        <a class="option-link all-a-link">
          {{ route.label | translate | capitalize }}
        </a>
      </nz-option>
    </nz-option-group>
  </nz-select>
</nz-input-group>
<ng-template #prefixIcon>
  @if (isMobileApp) {
    <i class="fal fa-search color--grey-dark by-fs-20"></i>
  }
</ng-template>
<ng-template #suffixIcon>
  <i
    (click)="isMicrophoneActive = !isMicrophoneActive"
    class="fas fa-microphone mic-{{
      isMicrophoneActive ? 'enabled' : 'disabled'
    }}"
    [ngClass]="{ mobile: responsive }"
    [class.by-fs-18]="isMobileApp"
  ></i>
</ng-template>

<ng-template #guestsOptionTemplate let-data="data">
  <a class="global-search-result">
    <div>
      <div class="avatar by-mr-2">
        <nz-avatar
          *ngIf="data?.option?.facebook; else avatarTemplate"
          nzSize="large"
          [nzSrc]="
            'https://unavatar.vercel.app/facebook/' + data?.option?.facebook
          "
        ></nz-avatar>
      </div>
      <div>
        <div nz-row>
          <strong>{{ data?.option?.subject | titlecase }}</strong>
        </div>
        <div nz-row>
          <span
            >#{{ data?.option?.room }} - {{ data?.option?.accommodation }}</span
          >
        </div>
        <div *ngIf="data?.option?.date_from" nz-row>
          <span
            >{{ data?.option?.date_from | toDate | date: 'dd/MM/yyyy' }} -
            {{ data?.option?.date_to | toDate | date: 'dd/MM/yyyy' }}</span
          >
        </div>
      </div>
    </div>
  </a>
</ng-template>

<ng-template #roomsOptionTemplate let-data="data">
  <a class="global-search-result">
    <div>
      <div class="avatar by-mr-2">
        <nz-avatar
          *ngIf="data?.option?.facebook; else avatarTemplate"
          nzSize="large"
          [nzSrc]="
            'https://unavatar.vercel.app/facebook/' + data?.option?.facebook
          "
        ></nz-avatar>
      </div>
      <div>
        <div nz-row>
          <strong>#{{ data?.option?.subject | upperfirst }}</strong>
        </div>
        <div nz-row *ngIf="data?.option?.guest_name">
          <span>
            {{ data?.option?.guest_name | titlecase }}
            {{ data?.option?.guest_surname | titlecase }}
          </span>
        </div>
        <div *ngIf="data?.option?.date_from" nz-row>
          <span
            >{{ data?.option?.date_from | toDate | date: 'dd/MM/yyyy' }} -
            {{ data?.option?.date_to | toDate | date: 'dd/MM/yyyy' }}</span
          >
        </div>
      </div>
    </div>
  </a>
</ng-template>

<ng-template #avatarTemplate>
  <div class="user-avatar-template big">
    <i class="fal fa-user"></i>
  </div>
</ng-template>

<ng-template #searchSelectNotFound>
  <div class="text-align--center by-fs-25 color--azure by-mt-2">
    <i class="far fa-search"></i>
  </div>
  <div class="text-align--center color--azure by-mb-2">
    {{ 'search_for_more_results' | translate | upperfirst }}
  </div>
</ng-template>
