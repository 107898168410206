import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounce, get, truncate, upperCase } from 'lodash';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import 'quill-mention';
import { Subscription } from 'rxjs/internal/Subscription';

import * as Quill from 'quill';

import { TextEditorService } from './services/text-editor.service';
import { ISnippet } from '../../models';
import { TooltipModule } from '@swimlane/ngx-charts';
import { CommonModule } from '@angular/common';
import {
  NgCapitalizePipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';

const MentionBlot = Quill.import('blots/mention') as any;

class StyledMentionBlot extends MentionBlot {
  static render(data) {
    const tagHtmlRegex = /^<([a-zA-Z][^\s\/>]*)(?:\s[^<>]*?)?(\/?>)/;

    const element = document.createElement('div');

    element.innerHTML = data.value;

    if (tagHtmlRegex.test(data.value)) {
      return element.firstElementChild;
    }

    return element.firstChild;
  }
}

StyledMentionBlot.blotName = 'styled-mention';

Quill.register(StyledMentionBlot);

var alignStyle = Quill.import('attributors/style/align');

Quill.register(alignStyle, true);

const editorModules = <any>{
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    //['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    // [{ direction: 'rtl' }], // text direction

    // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    [{ align: [] }],
    ['clean'], // remove formatting button

    // link and image, video
    // ['link', 'image'],
    ['link'],
  ],
};

export interface IMention {
  id: number;
  value: string;
  description: string;
}

@Component({
  selector: 'by-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    CommonModule,
    NgCapitalizePipeModule,
    TranslateModule,
    QuillModule,
    FormsModule,
    NzToolTipModule,
    NgUpperFirstPipeModule,
    NzModalModule,
    NzFormModule,
    NzButtonModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextEditorComponent,
      multi: true,
    },
  ],
})
export class TextEditorComponent
  implements
    OnInit,
    ControlValueAccessor,
    OnDestroy,
    AfterContentInit,
    OnChanges
{
  @Input()
  set __disabled(disabled: boolean) {
    if (disabled) {
      const disabledStyle = { cursor: 'not-allowed' };
      this.style = { ...this.style, ...disabledStyle };
      this.editor.setDisabledState(disabled);
    }
  }
  @Input()
  id: number | string;
  @Input()
  style = <{ [property: string]: string }>{ 'min-height': '300px' };
  @Input()
  placeholder = this._translate.instant('placeholder.editor_placeholder');
  @Input()
  theme: 'snow';
  @Input()
  modules = editorModules;
  @Input()
  required = false;

  @Input() stripTag = false;
  @Input()
  set snippet(snippet: any) {
    if (!snippet) {
    }
    this.addTag(snippet);
  }
  @Input('snippets')
  set _snippets(snippets: ISnippet[]) {
    this.setSnippets(snippets);
  }
  @Input()
  languageIsoCode = 'it';

  @Input()
  genericTranslations: { [lang: string]: { [key: string]: string } };

  @Input() showEmailToolbar = true;

  @Input() showSmsToolbar = false;

  @Input() showChannelToolbar = false;

  @Input() showTagsButtom = false;

  @Output() showTags = new EventEmitter();

  @ViewChild('editor', { static: true }) editor: QuillEditorComponent;

  showEditor = true;
  _disabled = false;

  snippets: ISnippet[] = [];

  lastIndexOfEditor;
  onChange;
  value;
  quill: any;
  onChangeSubscription: Subscription;
  showLinkModal = false;
  linkLabelTag: string;

  item: any;
  inserItem;

  toolbarElement: Element;

  buttonSaveLoading = false;

  mentionFuncFactory = (snippets: IMention[]) => {
    return {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      source: (searchTerm: string, renderList) => {
        if (searchTerm.length === 0) {
          renderList(snippets, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < snippets.length; i++) {
            if (
              ~snippets[i].description // tslint:disable-next-line:no-bitwise
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase())
            ) {
              matches.push(snippets[i]);
            }
          }
          renderList(matches, searchTerm);
        }
      },
      renderItem: (item) => {
        return truncate(item.description, {
          length: 20,
        });
      },
      onSelect: (_item, _insertItem) => {
        if (_item.value.includes('link')) {
          this.onOpenLinkModal(_item, _insertItem);
        } else {
          _insertItem(_item);
        }
      },
      mentionDenotationChars: ['@'],
      dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target'],
      blotName: 'styled-mention',
      linkTarget: '_blank',
    };
  };

  castUrls: (event: any) => void;

  constructor(
    private _textEditorServices: TextEditorService,
    private _translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
    this.castUrls = debounce(this.castHttpsUrls.bind(this), 400);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (!this.stripTag) {
      return;
    }
    const clipboard: DataTransfer =
      event.clipboardData || window['clipboardData'];

    event.preventDefault();
    event.stopPropagation();

    const tmp = document.createElement('div');

    tmp.innerHTML = clipboard.getData('text/plain');

    const purifiedText = tmp.textContent || tmp.innerText || '';

    document.execCommand('insertHTML', false, purifiedText);
  }

  ngAfterContentInit() {}

  onShowTag(e: Event) {
    this.showTags.emit();

    e?.preventDefault();

    e?.stopPropagation();
  }

  ngOnChanges() {
    this.setSnippets(this.snippets);
  }

  ngOnInit() {
    this.onChangeSubscription = this.editor.onContentChanged.subscribe(
      (data) => {
        if (data.editor.selection.cursor.selection.lastRange) {
          this.lastIndexOfEditor =
            data.editor.selection.cursor.selection.lastRange.index;
        }
        this.onChange(this.onTagFormatter((data.html || '').replace('@ ', '')));
        if (!data.text) {
          this.editor.quillEditor.writeValue(null);
        }
      },
    );
  }

  ngOnDestroy() {
    try {
      this.onChangeSubscription.unsubscribe();
    } catch (e) {}
  }

  writeValue(_value) {
    this.value = this.onTagFormatter(_value);
    // this.el.nativeElement.innerHTML = this.value;
    this.editor.writeValue(_value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {}

  handleAddTag(event) {
    if (event.range) {
      this.lastIndexOfEditor = event.range.index;
      this.quill = event.editor;
    }
  }

  castHttpsUrls() {
    const { delta } = this.editor?.quillEditor?.editor || {};
    let hasChanges = false;

    const newDelta = delta?.ops?.map((item) => {
      let link = item?.attributes?.link;
      if (link && !link.startsWith('https://') && !link.startsWith('mailto:')) {
        if (link.startsWith('http://')) {
          link = link.slice(7);
        }

        hasChanges = true;

        return {
          ...item,
          attributes: {
            ...item.attributes,
            link: `https://${link}`,
          },
        };
      }

      return item;
    });

    if (hasChanges) {
      this.editor.quillEditor.setContents(newDelta);
    }
  }

  addTag(_snippet: ISnippet) {
    if (!this.editor.quillEditor || !_snippet) {
      return;
    }

    const snippet = this.snippetsToMentions([_snippet])[0];

    this.editor.quillEditor.insertEmbed(
      this.lastIndexOfEditor,
      'styled-mention',
      {
        denotationChar: '@',
        ...snippet,
      },
    );

    this.insertPinMention(_snippet);
  }

  setSnippets(_snippets: ISnippet[]) {
    if (!_snippets) {
      return;
    }
    this.snippets = _snippets || [];
    let snippets = [];
    if (_snippets) {
      snippets = _snippets;
    }
    const newSnippets = this.snippetsToMentions(snippets);
    const mention = this.mentionFuncFactory(newSnippets);
    this.modules = { ...this.modules, mention };
  }

  snippetsToMentions(snippets: ISnippet[]): IMention[] {
    return snippets.map((snippet) => {
      const { description, snippet_code } = snippet;
      return <IMention>{
        id:
          get(snippet, 'id') ||
          get(snippet, ['translations', 0, 'template_snippet_code_id']),
        description: description,
        value: snippet_code,
      };
    });
  }

  getContents() {
    return this.editor.quillEditor.getContents();
  }

  modalCancel() {
    this.showLinkModal = false;
  }

  findInContent(value: string): boolean {
    return JSON.stringify(this.editor?.quillEditor?.getContents())?.includes(
      value,
    );
  }

  insertPinMention(item) {
    if (
      +item?.id === 3 ||
      +get(item, ['translations', 0, 'template_snippet_code_id']) === 3
    ) {
      let delta = this.editor.quillEditor.editor.delta.ops;

      if (this.findInContent('{{% reservation.checkin_online_pin %}}')) {
        return;
      }

      const checkinOnlinePinSnippet = this.snippets.find(
        ({ id, translations }) =>
          id === 85 ||
          get(translations, [0, 'template_snippet_code_id']) === 85,
      );

      if (!checkinOnlinePinSnippet) {
        return;
      }

      const checkinPinMention = this.snippetsToMentions([
        checkinOnlinePinSnippet,
      ]);
      delta = [
        ...delta,
        {
          insert: upperCase(
            `${get(
              this.genericTranslations,
              [this.languageIsoCode, 'pin'],
              'pin',
            )} :`,
          ),
        },
        { insert: ': ' },
        {
          insert: {
            mention: {
              denotationChar: '@',
              ...checkinPinMention[0],
            },
          },
        },
      ];
      this.editor.quillEditor.setContents(delta);
    }
  }

  modalOk() {
    this.buttonSaveLoading = true;

    this.item = {
      ...this.item,
      value: `<a href="${this.item.value}"  target="_blank">${this.linkLabelTag}</a>`,
    };

    this.inserItem(this.item);

    this.insertPinMention(this.item);

    this.showLinkModal = false;

    this.buttonSaveLoading = false;
  }

  private onOpenLinkModal(item: any, insertItem) {
    this.item = item;
    this.inserItem = insertItem;

    this.showLinkModal = true;
    this.linkLabelTag = null;

    this.cdr.detectChanges();
  }

  onTagFormatter(body: string): string {
    const newBody = (body || '')
      .replace(
        /(@ )?{{%( |)+(\w+\.?\w+)( |)+%}}/g,
        (fullMatch: string, group1: string, group2: string, group3: string) => {
          return '{{% ' + group3.trim().toLowerCase() + ' %}}';
        },
      )
      .replace(/\uFEFF/g, ''); //MUNNIZZA PERCHE' I TAG AGGIUNGONO OVUNQUE QUESTO UNICODE
    return newBody;
  }

  get showToolbar() {
    return this.showEmailToolbar || this.showSmsToolbar;
  }
}
