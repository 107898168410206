import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import it from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { UiModule } from '@ui/ui.module';
import itDateFns from 'date-fns/locale/it';
import { it_IT, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';

import { ArrivalsDeparturesProgressModule } from '../components/arrivals-departures-progress/arrivals-departures-progress.module';
import { HelpModule } from '../components/help/help.module';
import { NotificationsContainerModule } from '../components/notifications-container/notifications-container.module';
import { NotificationsListModule } from '../components/notifications-list/notifications-list.module';
import { PriceListPeriodFormModule } from '../components/price-list-period-form/price-list-period-form.module';
import { PopoverScrollBlockerModule } from '../directives/popover-scroll-blocker/popover-scroll-blocker.module';
import { HighlightSearchModule } from '../pipes/highlight-search/highlight-search.module';
import { HighlightSearch } from '../pipes/highlight-search/highlight-search.pipe';

import { CoreEffects } from './+state/core.effects';
import * as fromCore from './+state/core.reducer';
import { AfterLoginModalComponent } from './app-shell/after-login-modal/after-login-modal.component';
import { AppShellMenuDirective } from './app-shell/app-shell-menu-directive/app-shell-menu.directive';
import { AppShellMenuComponent } from './app-shell/app-shell-menu/app-shell-menu.component';
import { AppShellPropertiesAccountExpiredComponent } from './app-shell/app-shell-properties-account-expired/app-shell-properties-account-expired.component';
import { AppShellComponent } from './app-shell/app-shell.component';
import { GlobalSearchComponent } from '../components/global-search/global-search.component';
import { MuliPropertyMenuComponent } from './app-shell/multi-property-menu-component/multi-property-menu.component';
import { CoreHttpInterceptor } from './http-interceptors/core-http-interceptor';
import { CheckinOutService } from './services/checkin-out.service';
import { AppShellUser2faWarningComponent } from './app-shell/app-shell-user-2fa-warning/app-shell-user-2fa-warning.component';
import { BottomSheetModule } from '../components/bottom-sheet/bottom-sheet.module';
import { MobileBarContainerComponent } from './app-shell/mobile-bar/mobile-bar-container/mobile-bar-container.component';
registerLocaleData(it);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    StoreModule.forFeature('core', fromCore.reducer),
    EffectsModule.forFeature([CoreEffects]),
    TranslateModule,
    UiModule,
    SharedModule,
    NgPipesModule,
    DragDropModule,
    ReactiveFormsModule,
    HelpModule,
    NotificationsContainerModule,
    NotificationsListModule,
    PopoverScrollBlockerModule,
    HighlightSearchModule,
    ArrivalsDeparturesProgressModule,
    PriceListPeriodFormModule,
    BottomSheetModule,
    MobileBarContainerComponent,
    GlobalSearchComponent,
  ],
  declarations: [
    AppShellComponent,
    AppShellMenuDirective,
    AppShellMenuComponent,
    AppShellPropertiesAccountExpiredComponent,
    AppShellUser2faWarningComponent,
    MuliPropertyMenuComponent,
    AfterLoginModalComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true,
    },
    CheckinOutService,
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: NZ_DATE_LOCALE, useValue: itDateFns },
    { provide: NZ_I18N, useValue: it_IT },
  ],
  exports: [TranslateModule, HighlightSearch],
})
export class CoreModule {}
