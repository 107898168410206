<div class="notifications-list">
  <div class="notifications-list__header">
    <nz-alert
      class="by-mt-2"
      *byIsAdmin
      nzType="warning"
      [nzMessage]="
        'admin_cant_edit_notifications_warning' | translate | upperfirst
      "
      nzShowIcon
    ></nz-alert>

    <by-page-header
      [title]="'notifications_title' | translate | upperfirst"
      [byClass]="'by-mb-15'"
    >
      <span class="by-mr-5">{{
        'show_just_unread' | translate | upperfirst
      }}</span>
      <nz-switch
        nzSize="small"
        [(ngModel)]="showUnread"
        (ngModelChange)="setNotificationsSource()"
      ></nz-switch>
    </by-page-header>

    <div class="notifications-toolbar-container">
      <div *byIsAdmin class="by-flex by-flex-middle by-flex-1">
        <by-date-range-selector
          class="date-range-max-width"
          buttonType="default"
          [formControl]="dateRange"
        ></by-date-range-selector>
        <i
          nz-tooltip
          [nzTooltipTitle]="'admin_operation' | translate | upperfirst"
          class="by-ml-10 fas fa-user-shield color--azure"
        ></i>
      </div>
      <div *ngIf="notifications?.length" class="by-flex by-flex-1 by-flex-end">
        <a (click)="onMarkAllAsRead()">{{
          'mark_all_as_read' | translate | upperfirst
        }}</a>
      </div>
    </div>
  </div>

  <cdk-virtual-scroll-viewport
    [hidden]="!notifications?.length || loading"
    itemSize="140"
    minBufferPx="500"
    maxBufferPx="500"
    class="notifications-list__list"
  >
    <div
      *cdkVirtualFor="let notification of notifications"
      class="notifications-list__item"
      (click)="onClickNotification(notification)"
      [ngClass]="{
        'notifications-list__item--unread': !notification.read_at
      }"
    >
      <div class="by-flex by-flex-middle by-flex-between">
        <div class="by-mr-2">
          <img [src]="notification.data.icon" width="32" />
        </div>

        <div>
          <div class="by-clamp-text-2">
            <strong>{{ notification.data.title }}</strong>
          </div>
          <div
            *ngIf="
              notification.data.body ||
              notification.data.message as notificationBody
            "
            class="by-clamp-text-3"
            nz-tooltip
            [nzTooltipTitle]="notificationBody"
          >
            {{ notificationBody }}
          </div>
          <div class="notifications-list__item-date">
            {{ notification.created_at | formatDate: 'DD/MM/YYYY HH:mm' }}
          </div>
        </div>
      </div>

      <div>
        <button
          class="notifications-list__unread-indicator-wrapper"
          (click)="$event.stopPropagation(); onToggleRead(notification)"
          nz-tooltip
          [nzTooltipTitle]="
            (notification.read_at ? 'marck_as_unread' : 'marck_as_read')
              | translate
              | upperfirst
          "
        >
          <i
            class="fas fa-circle"
            [ngClass]="{
              'color--azure': !notification.read_at,
              'color--transparent': notification.read_at
            }"
          ></i>
        </button>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>

  <div
    [hidden]="notifications?.length || loading"
    class="notifications-list__list notifications-list__list--empty"
  >
    <div class="notifications-list__list--empty-icon">
      <img width="70" [src]="cdnUrl + 'images/beddy/svg/smile-wink.svg'" />
    </div>
    <div class="notifications-list__list--empty-description">
      {{ 'no_notifications_to_read' | translate | upperfirst }}
    </div>
  </div>

  <div [hidden]="!loading" class="notifications-list__list">
    <div *ngFor="let _ of [] | range: 3 : 0" class="notifications-list__item">
      <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
    </div>
  </div>
</div>
