<nz-form-item [ngStyle]="byStyle">
  <div class="by-w-100" [ngClass]="defaultClass">
    <label class="label-question">
      <span class="input-group-text-custom">
        {{ label | upperfirst }}
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
      </span>
    </label>

    <nz-form-control class="by-flex-nogrow">
      <nz-radio-group
        nzButtonStyle="solid"
        [formControl]="control"
        [nzDisabled]="isDisabled"
        class="by-flex option-container by-flex-end"
      >
        <label
          #nzRadioButton
          nz-radio-button
          [nzValue]="yesValue"
          class="question__option"
          [ngClass]="{ 'question__option--small': typeLayout === 'small' }"
          >{{ 'yes' | translate | uppercase }}</label
        >
        <label
          #nzRadioButton
          nz-radio-button
          [nzValue]="noValue"
          class="question__option"
          [ngClass]="{ 'question__option--small': typeLayout === 'small' }"
          >{{ 'no' | translate | uppercase }}</label
        >
      </nz-radio-group>
    </nz-form-control>
  </div>
</nz-form-item>
