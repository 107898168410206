import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { removeNullishValues } from '../helpers';
import { SplitBillsBulkRequest } from '../models';
import { SearchParams } from '../models/objects/search-params';
import { EditBillsRequest } from '../models/requests/edit-bills.request';
import { ActivChargeService } from './active-charges.service';
import { switchMap } from 'rxjs';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';

@Injectable({ providedIn: 'root' })
export class ReservationDetailsBillsService {
  constructor(
    private http: HttpClient,
    private activeCharge: ActivChargeService,
  ) {}

  loadBills(reservation_id: number, searchParams: SearchParams) {
    return this.http.get(
      `reservation/${reservation_id}/bills?${generateSearchQuery(
        searchParams,
      )}`,
      { headers: { accept: 'application/x.bdy.v1.1+json' } },
    );
  }

  loadBillDetails(billId: number) {
    return this.http.get(`bill/${billId}`);
  }

  deleteBill(reservation_id: number, reservation_bill_ids: number[]) {
    return this.http.delete(
      `reservation/${reservation_id}/bills?${generateParamArray(
        'reservation_bill_ids',
        reservation_bill_ids,
      )}`,
    );
  }

  deleteBillCategory(reservation_id: number, addon_category_id: number) {
    return this.http.delete(
      `reservation/${reservation_id}/addon_category/${addon_category_id}/bills`,
    );
  }

  setBillVisibility({ reservationId, billId, visible }) {
    return this.http.post(
      `reservation/${reservationId}/bill/${billId}/toggle`,
      { visible: +visible },
    );
  }

  moveBills(payload) {
    return this.http.post(`reservation/move_bills`, payload);
  }

  restoreMovedBills(bill_restore_id: number[], force_operation: boolean) {
    return this.http.post(`bills/restore`, {
      bill_restore_id,
      force_operation,
    });
  }

  splitBills(bill_id: number, payload) {
    const { createInvoicePayload } = payload;

    if (createInvoicePayload) {
      return this.activeCharge.create(createInvoicePayload).pipe(
        switchMap(({ data }: IResponseSuccess) => {
          const invoice = data[createInvoicePayload.property_id[0]];
          return this.transferBill(bill_id, {
            ...payload,
            invoice_id: invoice.id,
          });
        }),
      );
    }

    return this.transferBill(bill_id, payload);
  }

  transferBill(bill_id: number, payload) {
    const { split_type, createInvoicePayload, ...clearedPayload } = payload;

    return this.http.post(`transfer_bill/${bill_id}`, {
      ...clearedPayload,
      type: split_type,
    });
  }

  splitBillsBulk(request: SplitBillsBulkRequest) {
    return this.http.post(`transfer_bills/bulk`, request);
  }

  loadWarnings(reservationId: number) {
    return this.http.get(`reservation/${reservationId}/bills/warnings`);
  }

  searchBills(reservationId: number, label: string, filters: SearchParams) {
    return this.http.get(
      `reservation/${reservationId}/bills/lookup?${generateSearchQuery(
        removeNullishValues({
          label,
          ...filters,
        }),
      )}`,
    );
  }

  editBills(reservationId: number, bills: EditBillsRequest) {
    return this.http.put(`reservation/${reservationId}/bills`, { bills });
  }

  loadBuyers(reservationId: number, params: SearchParams) {
    return this.http.get(
      `reservations/${reservationId}/bills/buyers?${generateSearchQuery(
        params,
      )}`,
    );
  }
}
