import { createAction, props } from '@ngrx/store';
import { Hooks } from '../../helpers';

import {
  BillSearchOption,
  CreateActiveChargeRequest,
  CustomerLookup,
  IBills,
  SplitBillsBulkRequest,
} from '../../models';
import { ReservationBillsWarnings } from '../../models/objects/reservation-bills-warning';
import { SearchParams } from '../../models/objects/search-params';
import { EditBillsRequest } from '../../models/requests/edit-bills.request';

/**
 * L'azione loadRequest richiama tutti gli addebiti della prenotazione filtrati per categoria o per data
 */
export const loadRequest = createAction(
  '[Reservation Details Bills v2] Load Request',
  props<{
    reservationId: number;
    params: SearchParams;
    noLoading?: boolean;
  }>(),
);
export const loadSuccess = createAction(
  '[Reservation Details Bills v2] Load Success',
  props<{
    bills: IBills;
    filter: 'default' | 'date';
  }>(),
);
export const loadFailure = createAction(
  '[Reservation Details Bills v2] Load Failure',
  props<{ error: any }>(),
);

/**
 * L'azione deleteBillRequest elimina un singolo addebito
 */
export const deleteBillRequest = createAction(
  '[Reservation Details Bills v2] Delete Bill Request',
  props<{
    reservationId: number;
    reservationBillIds: number[];
    addonId?: number;
    invoiceId?: number;
    addonDetailId?: number;
    typeView?: 'default' | 'date';
  }>(),
);
export const deleteBillSuccess = createAction(
  '[Reservation Details Bills v2] Delete Bill Success',
  props<{
    reservationBillId: number;
    addonId: number;
    addonDetailId: number;
  }>(),
);
export const deleteBillFailure = createAction(
  '[Reservation Details Bills v2] Delete Bill Failure',
  props<{ error: any }>(),
);

/**
 * L'azione setBillVisibility nasconde o rende visibile un addebito
 */
export const setBillVisibilityRequest = createAction(
  '[Reservation Details Bills v2] Set Bill Visibility Request',
  props<{
    reservationId: number;
    billId: number;
    visible: boolean;
    referenece_type?: 'accommodation' | 'addon';
    referenece_id?: number;
  }>(),
);
export const setBillVisibilitySuccess = createAction(
  '[Reservation Details Bills v2] Set Bill Visibility Success',
  props<{
    referenece_type?: 'accommodation' | 'addon';
    referenece_id?: number;
  }>(),
);
export const setBillVisibilityFailure = createAction(
  '[Reservation Details Bills v2] Set Bill Visibility Failure',
  props<{ error: any }>(),
);

/**
 * L'azione moveBills sposta degli addebiti verso un'altra reservation_accommodation
 */
export const moveBillsRequest = createAction(
  '[Reservation Details Bills v2] Move Bills Request',
  props<{
    bill_id: number[];
    reservation_accommodation_id: number;
    customer_id?: number;
    origin: 'reservation' | 'general_invoice';
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const moveBillsSuccess = createAction(
  '[Reservation Details Bills v2] Move Bills Success',
);
export const moveBillsFailure = createAction(
  '[Reservation Details Bills v2] Move Bills Failure',
  props<{ error: any }>(),
);

/**
 * L'azione openWarningsModal mostra la modale con gli avvisi restituiti dalle API. In caso di conferma dispatcha l'azione passata come parametro.
 * (restoreMovedBillsRequest)
 */

export const openWarningsModal = createAction(
  '[Reservation Details Bills v2]Open Warnings Modal',
  props<{
    message: string;
    bill_restore_id: number[];
  }>(),
);

/**
 * L'azione restoreMovedBills ripristina gli addebiti spostati verso un'altra reservation_accommodation nella reservation_accommodation originale
 */

export const restoreMovedBillsRequest = createAction(
  '<[Reservation Details Bills v2] Restore Moved Bills Request>',
  props<{
    bill_restore_id: number[];
    force_operation?: boolean;
    success_message?: string;
  }>(),
);
export const restoreMovedBillsSuccess = createAction(
  '[Reservation Details Bills v2] Restore Moved Bills Success',
);
export const restoreMovedBillsFailure = createAction(
  '[Reservation Details Bills v2] Restore Moved Bills Failure',
  props<{ error: any }>(),
);

/**
 * L'azione spliBills splitta un addebito verso un'altra reservation_accommodation o conto passante
 */
export const splitBillsRequest = createAction(
  '[Reservation Details Bills v2] Split Bills Request',
  props<{
    bill_id: number;
    split_type: 'per_quantity' | 'per_price';
    customer_id?: number;
    reservation_accommodation_id?: number;
    invoice_id?: number;
    value: number;
    hooks?: Hooks;
    createInvoicePayload?: CreateActiveChargeRequest;
  }>(),
);
export const splitBillsSuccess = createAction(
  '[Reservation Details Bills v2] Split Bills Success',
);
export const splitBillsFailure = createAction(
  '[Reservation Details Bills v2] Split Bills Failure',
  props<{ error: any }>(),
);

/**
 * L'azione spliBillsBulk effettua una suddivisione percentuale di uno o più addebiti
 */
export const splitBillsBulkRequest = createAction(
  '[Reservation Details Bills v2] Split Bills Bulk Request',
  props<{
    request: SplitBillsBulkRequest;
    hooks?: Hooks;
  }>(),
);
export const splitBillsBulkSuccess = createAction(
  '[Reservation Details Bills v2] Split Bills Bulk Success',
);
export const splitBillsBulkFailure = createAction(
  '[Reservation Details Bills v2] Split Bills Bulk Failure',
  props<{ error: any }>(),
);

/**
 * L'azione loadBillsWarnings carica i warnings sugli addebiti causati da modifiche dei portali
 */
export const loadBillsWarningsRequest = createAction(
  '[Reservation Details Bills v2] Load Bills Warnings Request',
  props<{
    reservation_id: number;
  }>(),
);
export const loadBillsWarningsSuccess = createAction(
  '[Reservation Details Bills v2] Load Bills Warnings Success',
  props<{
    warnings: ReservationBillsWarnings;
  }>(),
);
export const loadBillsWarningsFailure = createAction(
  '[Reservation Details Bills v2] Load Bills Warnings Failure',
  props<{ error: any }>(),
);

/**
 * L'azione searchBillsRequest cerca i bills con determinati filtri
 */
export const searchBillsRequest = createAction(
  '[Reservation Details Bills v2] Search Bills Request',
  props<{
    reservation_id: number;
    label: string;
    filters?: {
      invoiced_status?: 'to_invoice' | 'invoice' | 'to_refund';
      customer_id?: number;
      reservation_accommodation_id?: number;
    };
  }>(),
);
export const searchBillsSuccess = createAction(
  '[Reservation Details Bills v2] Search Bills Success',
  props<{
    billsSearchResults: BillSearchOption[];
  }>(),
);
export const searchBillsFailure = createAction(
  '[Reservation Details Bills v2] Search Bills Failure',
  props<{ error: any }>(),
);
export const resetSearchBillsResults = createAction(
  '[Reservation Details Bills v2] Reset Search Bills Results',
);

/**
 * L'azione editBills modifica gli addebiti di una prenotazione
 */
export const editBillsRequest = createAction(
  '[Reservation Details Bills v2] Edit Bills Request',
  props<{
    reservationId: number;
    bills: EditBillsRequest;
    onSuccess?: () => void;
  }>(),
);
export const editBillsSuccess = createAction(
  '[Reservation Details Bills v2] Edit Bills Success',
);
export const editBillsFailure = createAction(
  '[Reservation Details Bills v2] Edit Bills Failure',
  props<{ error: any }>(),
);

/**
 * L'azione loadBuyers modifica gli addebiti di una prenotazione
 */
export const loadBuyersRequest = createAction(
  '[Reservation Details Bills v2] Load Buyers Request',
  props<{
    reservationId: number;
    params: SearchParams;
  }>(),
);
export const loadBuyersSuccess = createAction(
  '[Reservation Details Bills v2] Load Buyers Success',
  props<{
    allBillsCustomers: CustomerLookup[];
  }>(),
);
export const loadBuyersFailure = createAction(
  '[Reservation Details Bills v2] Load Buyers Failure',
  props<{ error: any }>(),
);

/**
 * L'azione resetState accetta un parametro keys.
 * Se viene passato keys l'azione resetterà solo le chiavi specificate nell'array.
 * Se non viene passato keys l'azione resetterà tutto lo stato
 */
export const resetState = createAction(
  '[Reservation Details Bills v2] Reset State',
);
