import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NzSelectComponent, NzSelectModule } from 'ng-zorro-antd/select';
import { pipe } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { selectAllProperties } from '../../core/+state/core.reducer';
import { replaceAll } from '../../helpers/replace-all';
import {
  GlobalSearchStoreActions,
  GlobalSearchStoreSelectors,
} from '../../root-store/global-search-store';
import { RootState } from '../../root-store/root-state';
import { DateFormatterService } from '../../core/services/date-formatter.service';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgCapitalizePipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { CommonModule } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { SpeechRecognitionDirective } from '@app/core/directives/speech-recognition.directive';
import { NzInputModule } from 'ng-zorro-antd/input';

interface SearchRoute {
  path: string[];
  queryParamsConditions: {
    regExp: string;
    queryParamsKey: string;
  }[];
  label: string;
  newFiltersComponent: boolean;
}

@Component({
  selector: 'by-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  standalone: true,
  imports: [
    PipesModule,
    NzSelectModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    NgCapitalizePipeModule,
    CommonModule,
    NzAvatarModule,
    FormsModule,
    SpeechRecognitionDirective,
    NzInputModule,
  ],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @Input() responsive = false;

  @Input() isMobileApp = false;

  @ViewChild('globalSearch') globalSearch: NzSelectComponent;

  isMicrophoneActive = false;

  searchControl = this.formBuilder.control('');

  showSelectPlaceholder = true;

  propertiesIds = [];

  results$ = this.store.select(
    pipe(GlobalSearchStoreSelectors.selectGlobalSearchData),
  );

  selectedItem = null;

  readonly routes: SearchRoute[] = [
    {
      path: ['/reservations/list'],
      queryParamsConditions: [
        { regExp: '^[0-9]*$', queryParamsKey: 'xml_reservation_id' },
        { regExp: '[0-9]|#', queryParamsKey: 'reference_number' },
        { regExp: '.*', queryParamsKey: 'customer' },
      ],
      label: 'reservations',
      newFiltersComponent: true,
    },
    {
      path: ['/price-quotations/all'],
      queryParamsConditions: [
        { regExp: '-', queryParamsKey: 'number' },
        { regExp: '.*', queryParamsKey: 'full_customer' },
      ],
      label: 'priceQuotations',
      newFiltersComponent: true,
    },
    {
      path: ['/customers/people'],
      queryParamsConditions: [{ regExp: '.*', queryParamsKey: 'value' }],
      label: 'customers',
      newFiltersComponent: false,
    },
    {
      path: ['/customers/companies'],
      queryParamsConditions: [{ regExp: '.*', queryParamsKey: 'name' }],
      label: 'companies',
      newFiltersComponent: false,
    },
    {
      path: ['/documents/invoices/issued'],
      queryParamsConditions: [
        { regExp: '.*', queryParamsKey: 'number_complete' },
      ],
      label: 'invoices',
      newFiltersComponent: false,
    },
    {
      path: ['/documents/receipts/issued'],
      queryParamsConditions: [
        { regExp: '.*', queryParamsKey: 'number_complete' },
      ],
      label: 'receipts',
      newFiltersComponent: false,
    },
  ];

  private subs = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateFormatter: DateFormatterService,
    private store: Store<RootState>,
    private router: Router,
  ) {}

  get inputHtmlElement(): HTMLInputElement {
    return document.querySelector('#searchSelect').querySelector('input');
  }

  ngOnInit() {
    this.subs.add(
      this.store.pipe(select(selectAllProperties)).subscribe((properties) => {
        this.propertiesIds = properties.map(({ id }) => id);
      }),
    );
    this.subs.add(
      this.searchControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value: string) => {
          const newValue = replaceAll(value, '#', '');
          if (newValue.trim() === '') {
            this.store.dispatch(
              new GlobalSearchStoreActions.LoadSuccessAction({
                data: {
                  guests: [],
                  rooms: [],
                },
              }),
            );
            this.showSelectPlaceholder = true;
            return;
          }
          const now = this.dateFormatter.toServerFormat(new Date());
          this.store.dispatch(
            new GlobalSearchStoreActions.LoadRequestAction({
              value: newValue,
              now,
              propertiesIds: this.propertiesIds,
            }),
          );
        }),
    );
  }

  ngOnDestroy() {
    this.store.dispatch(new GlobalSearchStoreActions.ResetSuccessAction());
    this.subs.unsubscribe();
  }

  onSearch(text: string) {
    this.searchControl.patchValue(text);
  }

  onSpeechResult(text: string) {
    this.searchControl.patchValue(text);
    this.isMicrophoneActive = false;
    this.showSelectPlaceholder = false;
    this.globalSearch.setOpenState(true);
    this.inputHtmlElement.value = text;
  }

  onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.reset(true);
    }
  }

  onChange(value: any) {
    if (typeof value === 'string') {
      this.navigateOnRoute(value);
    }

    if (typeof value === 'object') {
      this.navigateOnReservation(value);
    }

    this.reset(false);
  }

  private navigateOnReservation(result: any) {
    if (!result) {
      return;
    }

    const { reservation_id, roomreservation_id } = result;

    this.router.navigate(['/reservation', reservation_id], {
      queryParams: roomreservation_id && {
        roomReservationId: roomreservation_id,
      },
    });
  }

  private navigateOnRoute(value: string) {
    const foundRoute = this.routes.find((r) => r.label === value);

    if (!foundRoute) {
      return;
    }

    const { path, queryParamsConditions, newFiltersComponent } = foundRoute;

    const searchControlValue = this.searchControl.value?.trim();

    const queryParamsKey = queryParamsConditions.find(({ regExp }) =>
      new RegExp(regExp).test(searchControlValue),
    ).queryParamsKey;

    const params = {
      [queryParamsKey]: replaceAll(searchControlValue, '#', ''),
    };

    const queryParams = newFiltersComponent
      ? {
          search: window.btoa(JSON.stringify(params)),
        }
      : params;

    this.router.navigate(path, {
      queryParams,
    });
  }

  private reset(shouldWrite: boolean) {
    if (shouldWrite) {
      this.globalSearch.writeValue(null);
    }

    this.inputHtmlElement.blur();
    this.showSelectPlaceholder = true;
    this.selectedItem = null;

    this.store.dispatch(new GlobalSearchStoreActions.ResetSuccessAction());
  }
}
